import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { supabase } from "/Users/angelomontini/Library/Mobile Documents/com~apple~CloudDocs/trydo/src/system/supaclient.js";

function PageHelmet({ pageId }) {
  const [metaData, setMetaData] = useState(null);
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const { data, error } = await supabase
          .from("meta")
          .select("*")
          .eq("id", pageId);

        if (error) {
          console.error("Errore nel caricamento dei dati:", error);
          return;
        }

        if (data && data.length > 0) {
          setMetaData(data[0]);
          setIsHomePage(pageId === 1);
        }
      } catch (error) {
        console.error("Si è verificato un errore:", error);
      }
    };

    fetchPortfolioData();
  }, [pageId]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cmp.osano.com/169kLpTvDuBSD3Avo/e0fa3cf0-013c-4004-bf3b-ab13e8734e30/osano.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    window._paq = window._paq || [];
    window._paq.push(["trackPageView"]);
    window._paq.push(["enableLinkTracking"]);

    (function () {
      const u = "//esempio.angelomontini.com/";
      window._paq.push(["setTrackerUrl", u + "matomo.php"]);
      window._paq.push(["setSiteId", "41"]);
      const d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode.insertBefore(g, s);
    })();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{metaData ? metaData.title : "Caricamento..."}</title>
        <meta
          name="description"
          content={metaData ? metaData.description : ""}
        />

        {isHomePage && (
          <link rel="preload" href="/assets/images/homeweb.webp" as="image" />
        )}
      </Helmet>
    </React.Fragment>
  );
}

export default PageHelmet;
